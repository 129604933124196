/* Define a custom web font
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Thin.woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Extralight.woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Ultrabold.woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Ysabeau';
  src: url("http://localhost:8080/Ysabeau-master/webfonts/Ysabeau-Heavy.woff2");
  font-weight: 900;
  font-style: normal;
} */

/* src: url("http://localhost:8080/LilGrotesk-master/fonts/webfonts/LilGrotesk-Regular.woff2") */
@font-face {
    font-family: "MagnetGrotesk";
    src: local('LilGrotesk-Regular'), url("./fonts/MagnetGrotesk-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "MagnetGrotesk";
    src: local('LilGrotesk-Bold'), url("./fonts/MagnetGrotesk-Bold.woff2") format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "MagnetHello";
    src: local('Hey-Regular'), url("./fonts/MagnetHello-Regular.woff2");
    font-weight: 400;
    font-style: normal;
}

h1,
h2,
h3,
h4 {
    font-family: "MagnetGrotesk";
}

html {
    font-size: 16pt;
}

body {
    margin: 0;
    font-family: "MagnetHello", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #180f48;
}

* {
    box-sizing: border-box;
}
